<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'AutoNotifications' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Auto-Notifications
          </v-btn>
          <h2 class="darkGrey--text">
            Members from Auto-Notification Group
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Auto-Notification Members
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Member
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" offset="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="membersSample"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
            :loading="loading"
            :search="search"
          >
            <template v-slot:item.name="{ item }">
              <span class="font-weight-bold d-flex py-4">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.email="{ item }">
              {{ item.email }}
            </template>
            <template v-slot:item.alternativeEmail="{ item }">
              {{ item.alternativeEmail }}
            </template>
            <template v-slot:item.actions>
              <div class="d-flex flex-row justify-center">

                <v-tooltip
                  top
                  v-for="(itemMenu, index) in itemsMenu"
                  :key="index"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="openModal(itemMenu.actions, itemMenu.optional)"
                    >
                      <v-icon
                        size="20"
                        :color="itemMenu.type? itemMenu.type:'darkGrey'"
                      >
                        {{ itemMenu.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                      {{ itemMenu.text }}
                    </span>
                </v-tooltip>

              </div>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <auto-notification-member-create-update v-model="modals.createUpdate" :modeType="modeType"></auto-notification-member-create-update>
    <auto-notification-member-delete v-model="modals.delete"></auto-notification-member-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import AutoNotificationMemberCreateUpdate from '@/components/AutoNotifications/AutoNotificationMemberCreateUpdate'
import AutoNotificationMemberDelete from '@/components/AutoNotifications/AutoNotificationMemberDelete'

export default Vue.extend({
  name: 'AutoNotificationMembers',
  components: {
    Portal,
    AutoNotificationMemberCreateUpdate,
    AutoNotificationMemberDelete
  },
  data: () => ({
    modeType: '',
    modals: {
      createUpdate: false,
      delete: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Auto-Notifications',
        disabled: false,
        href: ''
      },
      {
        text: 'Members from Auto-Notification Group Name',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Alternative Email', value: 'alternativeEmail', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    membersSample: [
      { name: 'Rico Reis', email: 'rico@email.com', alternativeEmail: 'rico@otheremail.com' },
      { name: 'Vee Caron', email: 'vee@email.com', alternativeEmail: 'vee@otheremail.com' },
      { name: 'Charles Simon', email: 'charles@email.com', alternativeEmail: 'charles@otheremail.com' }
    ],
    loading: false,
    search: ''
  }),
  methods: {
    openModal (item, optional) {
      if (optional) this.modeType = optional
      this.modals[item] = true
    }
  }
})
</script>

<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span v-if="modeType=='create'">New Member</span>
          <span v-if="modeType=='edit'">Edit Member of</span>
        </span>
        <span v-if="modeType=='edit'"><span class="font-weight-black">Auto-Notification Group Name</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              hide-details
              label="First Name"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              hide-details
              label="Last Name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              hide-details
              label="Email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              hide-details
              label="Alternative Email (optional)"
            ></v-text-field>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">
          <span v-if="modeType=='create'">Add</span>
          <span v-if="modeType=='edit'">Update</span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AutoNotificationMemberCreateUpdate',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
})
</script>
